html {
    font-size: 14px;
}

body {
    font-family: 'Kalam', cursive;
    background-color: #aaaaaa00;
}

.title {
    font-size: 5em;
}

.subtitle {
    font-size: 2.5em;
}

.box {
    padding: 20px;
    border-radius: 10px;
}

.content {
    background: #ffffff70;
}

.content div {
    padding-top: 20px;
}

.nav-buttons {
    margin-top: 20px;
}

.nav-buttons button {
    font-size: 1.7em;
}
